import add from './add.svg'
import add_lyrics from './add_lyrics.svg'
import advanced_chords_mkt from './advanced_chords_mkt.svg'
import alert from './alert.svg'
import apple from './apple.svg'
import arrow_down from './arrow_down.svg'
import arrow_left from './arrow_left.svg'
import arrow_right from './arrow_right.svg'
import arrow_up from './arrow_up.svg'
import attachment from './attachment.svg'
import audio_file from './audio_file.svg'
import audio_files from './audio_files.svg'
import autoplay from './autoplay.svg'
import autotune from './autotune.svg'
import bass from './bass.svg'
import book from './book.svg'
import box from './box.svg'
import brand from './brand.svg'
import brand_full from './brand_full.svg'
import calendar from './calendar.svg'
import camera from './camera.svg'
import camera_flip from './camera_flip.svg'
import card from './card.svg'
import cart from './cart.svg'
import chat from './chat.svg'
import check from './check.svg'
import checkbox_cicle from './checkbox_cicle.svg'
import checkbox_off from './checkbox_off.svg'
import checkbox_on from './checkbox_on.svg'
import chevron_down from './chevron_down.svg'
import chevron_down_2 from './chevron_down_2.svg'
import chevron_left from './chevron_left.svg'
import chevron_right from './chevron_right.svg'
import chevron_up from './chevron_up.svg'
import chords from './chords.svg'
import chords_1 from './chords_1.svg'
import chords_detection from './chords_detection.svg'
import clock from './clock.svg'
import close from './close.svg'
import cloud_download from './cloud_download.svg'
import collections from './collections.svg'
import compressor from './compressor.svg'
import copy from './copy.svg'
import count_in from './count_in.svg'
import crop from './crop.svg'
import cue from './cue.svg'
import cue_back from './cue_back.svg'
import database from './database.svg'
import delay from './delay.svg'
import denoiser from './denoiser.svg'
import desktop from './desktop.svg'
import devices from './devices.svg'
import download from './download.svg'
import download_app from './download_app.svg'
import drums from './drums.svg'
import edit from './edit.svg'
import education from './education.svg'
import effects from './effects.svg'
import email from './email.svg'
import eq from './eq.svg'
import external_link from './external_link.svg'
import eye from './eye.svg'
import eye_off from './eye_off.svg'
import facebook from './facebook.svg'
import features from './features.svg'
import flash from './flash.svg'
import flip from './flip.svg'
import folder from './folder.svg'
import folder_plus from './folder_plus.svg'
import gift_box from './gift_box.svg'
import globe from './globe.svg'
import goals from './goals.svg'
import google from './google.svg'
import graph from './graph.svg'
import guitar from './guitar.svg'
import hamburger_menu2 from './hamburger_menu2.svg'
import hamburger_menu3 from './hamburger_menu3.svg'
import headphones from './headphones.svg'
import headphones_left from './headphones_left.svg'
import headphones_right from './headphones_right.svg'
import heart from './heart.svg'
import help from './help.svg'
import home from './home.svg'
import hourglass from './hourglass.svg'
import ic_computer from './ic_computer.svg'
import ic_ipad from './ic_ipad.svg'
import image from './image.svg'
import info from './info.svg'
import instagram from './instagram.svg'
import internet_signal from './internet_signal.svg'
import invite_user from './invite_user.svg'
import isolate_drums from './isolate_drums.svg'
import karaoke from './karaoke.svg'
import key_detection from './key_detection.svg'
import laptop from './laptop.svg'
import library from './library.svg'
import link from './link.svg'
import linkedin from './linkedin.svg'
import live_performance from './live_performance.svg'
import loader from './loader.svg'
import lock from './lock.svg'
import log_in from './log_in.svg'
import log_out from './log_out.svg'
import loop from './loop.svg'
import loop_section from './loop_section.svg'
import lyrics from './lyrics.svg'
import mastering from './mastering.svg'
import maximize from './maximize.svg'
import metronome from './metronome.svg'
import mic from './mic.svg'
import minimize from './minimize.svg'
import minus from './minus.svg'
import mixer from './mixer.svg'
import money from './money.svg'
import moon from './moon.svg'
import more from './more.svg'
import multiple_users from './multiple_users.svg'
import music from './music.svg'
import music_control from './music_control.svg'
import music_device from './music_device.svg'
import music_lover from './music_lover.svg'
import music_note from './music_note.svg'
import music_note_1 from './music_note_1.svg'
import music_notes from './music_notes.svg'
import new_collections from './new_collections.svg'
import next from './next.svg'
import no_internet_signal from './no_internet_signal.svg'
import no_mic from './no_mic.svg'
import no_money from './no_money.svg'
import no_music from './no_music.svg'
import no_search from './no_search.svg'
import no_volume from './no_volume.svg'
import notifications from './notifications.svg'
import offload_device from './offload_device.svg'
import pause from './pause.svg'
import pen from './pen.svg'
import pencil from './pencil.svg'
import piano from './piano.svg'
import pitch from './pitch.svg'
import play from './play.svg'
import play_audio from './play_audio.svg'
import play_circle from './play_circle.svg'
import play_on_repeat from './play_on_repeat.svg'
import playlist from './playlist.svg'
import plus from './plus.svg'
import previous from './previous.svg'
import quote from './quote.svg'
import radio_off from './radio_off.svg'
import redo from './redo.svg'
import refresh from './refresh.svg'
import refresh_back from './refresh_back.svg'
import reverb from './reverb.svg'
import rocket from './rocket.svg'
import save_time from './save_time.svg'
import search from './search.svg'
import sections from './sections.svg'
import settings from './settings.svg'
import share1 from './share1.svg'
import share_2 from './share_2.svg'
import shield from './shield.svg'
import signal_wave from './signal_wave.svg'
import skills from './skills.svg'
import sliders from './sliders.svg'
import smartphone from './smartphone.svg'
import song_detail from './song_detail.svg'
import song_parts from './song_parts.svg'
import songwriting from './songwriting.svg'
import sparkles from './sparkles.svg'
import speed from './speed.svg'
import speed_changer from './speed_changer.svg'
import spliter from './spliter.svg'
import star from './star.svg'
import star1 from './star1.svg'
import star2 from './star2.svg'
import star3 from './star3.svg'
import stop from './stop.svg'
import sun from './sun.svg'
import support from './support.svg'
import telegram from './telegram.svg'
import tiktok from './tiktok.svg'
import time_control from './time_control.svg'
import touch_id_personal from './touch_id_personal.svg'
import transpose from './transpose.svg'
import trash from './trash.svg'
import trim from './trim.svg'
import tuner from './tuner.svg'
import twitter from './twitter.svg'
import ukulele from './ukulele.svg'
import undo from './undo.svg'
import unlock from './unlock.svg'
import upload from './upload.svg'
import upload_1 from './upload_1.svg'
import upload_error from './upload_error.svg'
import user from './user.svg'
import user_group from './user_group.svg'
import user_voice from './user_voice.svg'
import video from './video.svg'
import video_file from './video_file.svg'
import video_files from './video_files.svg'
import vocals from './vocals.svg'
import volume0 from './volume0.svg'
import volume1 from './volume1.svg'
import volume2 from './volume2.svg'
import volume_bar from './volume_bar.svg'
import wave from './wave.svg'
import whatsapp from './whatsapp.svg'
import widget from './widget.svg'
import youtube from './youtube.svg'
import zoom_in from './zoom_in.svg'
import zoom_out from './zoom_out.svg'

const svg = {
  'add': add,
  'add_lyrics': add_lyrics,
  'advanced_chords_mkt': advanced_chords_mkt,
  'alert': alert,
  'apple': apple,
  'arrow_down': arrow_down,
  'arrow_left': arrow_left,
  'arrow_right': arrow_right,
  'arrow_up': arrow_up,
  'attachment': attachment,
  'audio_file': audio_file,
  'audio_files': audio_files,
  'autoplay': autoplay,
  'autotune': autotune,
  'bass': bass,
  'book': book,
  'box': box,
  'brand': brand,
  'brand_full': brand_full,
  'calendar': calendar,
  'camera': camera,
  'camera_flip': camera_flip,
  'card': card,
  'cart': cart,
  'chat': chat,
  'check': check,
  'checkbox_cicle': checkbox_cicle,
  'checkbox_off': checkbox_off,
  'checkbox_on': checkbox_on,
  'chevron_down': chevron_down,
  'chevron_down_2': chevron_down_2,
  'chevron_left': chevron_left,
  'chevron_right': chevron_right,
  'chevron_up': chevron_up,
  'chords': chords,
  'chords_1': chords_1,
  'chords_detection': chords_detection,
  'clock': clock,
  'close': close,
  'cloud_download': cloud_download,
  'collections': collections,
  'compressor': compressor,
  'copy': copy,
  'count_in': count_in,
  'crop': crop,
  'cue': cue,
  'cue_back': cue_back,
  'database': database,
  'delay': delay,
  'denoiser': denoiser,
  'desktop': desktop,
  'devices': devices,
  'download': download,
  'download_app': download_app,
  'drums': drums,
  'edit': edit,
  'education': education,
  'effects': effects,
  'email': email,
  'eq': eq,
  'external_link': external_link,
  'eye': eye,
  'eye_off': eye_off,
  'facebook': facebook,
  'features': features,
  'flash': flash,
  'flip': flip,
  'folder': folder,
  'folder_plus': folder_plus,
  'gift_box': gift_box,
  'globe': globe,
  'goals': goals,
  'google': google,
  'graph': graph,
  'guitar': guitar,
  'hamburger_menu2': hamburger_menu2,
  'hamburger_menu3': hamburger_menu3,
  'headphones': headphones,
  'headphones_left': headphones_left,
  'headphones_right': headphones_right,
  'heart': heart,
  'help': help,
  'home': home,
  'hourglass': hourglass,
  'ic_computer': ic_computer,
  'ic_ipad': ic_ipad,
  'image': image,
  'info': info,
  'instagram': instagram,
  'internet_signal': internet_signal,
  'invite_user': invite_user,
  'isolate_drums': isolate_drums,
  'karaoke': karaoke,
  'key_detection': key_detection,
  'laptop': laptop,
  'library': library,
  'link': link,
  'linkedin': linkedin,
  'live_performance': live_performance,
  'loader': loader,
  'lock': lock,
  'log_in': log_in,
  'log_out': log_out,
  'loop': loop,
  'loop_section': loop_section,
  'lyrics': lyrics,
  'mastering': mastering,
  'maximize': maximize,
  'metronome': metronome,
  'mic': mic,
  'minimize': minimize,
  'minus': minus,
  'mixer': mixer,
  'money': money,
  'moon': moon,
  'more': more,
  'multiple_users': multiple_users,
  'music': music,
  'music_control': music_control,
  'music_device': music_device,
  'music_lover': music_lover,
  'music_note': music_note,
  'music_note_1': music_note_1,
  'music_notes': music_notes,
  'new_collections': new_collections,
  'next': next,
  'no_internet_signal': no_internet_signal,
  'no_mic': no_mic,
  'no_money': no_money,
  'no_music': no_music,
  'no_search': no_search,
  'no_volume': no_volume,
  'notifications': notifications,
  'offload_device': offload_device,
  'pause': pause,
  'pen': pen,
  'pencil': pencil,
  'piano': piano,
  'pitch': pitch,
  'play': play,
  'play_audio': play_audio,
  'play_circle': play_circle,
  'play_on_repeat': play_on_repeat,
  'playlist': playlist,
  'plus': plus,
  'previous': previous,
  'quote': quote,
  'radio_off': radio_off,
  'redo': redo,
  'refresh': refresh,
  'refresh_back': refresh_back,
  'reverb': reverb,
  'rocket': rocket,
  'save_time': save_time,
  'search': search,
  'sections': sections,
  'settings': settings,
  'share1': share1,
  'share_2': share_2,
  'shield': shield,
  'signal_wave': signal_wave,
  'skills': skills,
  'sliders': sliders,
  'smartphone': smartphone,
  'song_detail': song_detail,
  'song_parts': song_parts,
  'songwriting': songwriting,
  'sparkles': sparkles,
  'speed': speed,
  'speed_changer': speed_changer,
  'spliter': spliter,
  'star': star,
  'star1': star1,
  'star2': star2,
  'star3': star3,
  'stop': stop,
  'sun': sun,
  'support': support,
  'telegram': telegram,
  'tiktok': tiktok,
  'time_control': time_control,
  'touch_id_personal': touch_id_personal,
  'transpose': transpose,
  'trash': trash,
  'trim': trim,
  'tuner': tuner,
  'twitter': twitter,
  'ukulele': ukulele,
  'undo': undo,
  'unlock': unlock,
  'upload': upload,
  'upload_1': upload_1,
  'upload_error': upload_error,
  'user': user,
  'user_group': user_group,
  'user_voice': user_voice,
  'video': video,
  'video_file': video_file,
  'video_files': video_files,
  'vocals': vocals,
  'volume0': volume0,
  'volume1': volume1,
  'volume2': volume2,
  'volume_bar': volume_bar,
  'wave': wave,
  'whatsapp': whatsapp,
  'widget': widget,
  'youtube': youtube,
  'zoom_in': zoom_in,
  'zoom_out': zoom_out,
}

export type SvgName = keyof typeof svg

export { svg }