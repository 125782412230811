import slugifyLib from 'slugify'

const replaceWithMarkup = (str: string, theme = 'dark') => {
  const highlightColor = theme === 'dark' ? 'text-acqua-500' : 'text-acqua-900'

  return str
    .replace(/\\n/g, `<br class="md:block" />`)
    .replace(/~(.*?)~/g, '<s class="line-through">$1</s>')
    .replace(/\*(.*?)\*/g, `<b>$1</b>`)
    .replace(/\*\*(.*?)\*\*/g, `<span class="${highlightColor}">$1</span>`)
}

const slugify = (content: string) => {
  if (content) {
    content = slugifyLib(content, {
      lower: true,
      strict: true,
      replacement: '_'
    })
  }

  return content
}

export { replaceWithMarkup, slugify }
