import { tv, VariantProps } from '@/utils/tv'
import * as colors from './button.colors'

export const button = tv({
  slots: {
    base: [
      'inline-flex',
      'items-center',
      'box-border',
      'no-underline',
      'appearance-none',
      'outline-none',
      'select-none',
      'subpixel-antialiased',
      'overflow-hidden',
      'tap-highlight-transparent',
      'transition-colors duration-300',
      'disabled:pointer-events-none',
      'disabled:opacity-50',
      'focus-visible:z-10',
      'focus-visible:outline-2',
      'focus-visible:outline-focus'
    ],
    image: ['max-w-md', 'pointer-events-none'],
    subTextWrapper: ['flex flex-col', 'text-left'],
    subText: ['text-display-10 leading-none', 'rtl:ml-auto'],
    icon: ['outline-none', 'shrink-0']
  },
  variants: {
    appearance: {
      text: '',
      solid: '',
      outline: '',
      ghost: ''
    },
    color: {
      white: '',
      whiteAcqua: '',
      black: '',
      gray: '',
      grayDark: '',
      acqua: '',
      acquaDark: '',
      turquoise: '',
      mint: ''
    },
    size: {
      xxs: {
        base: 'text-display-12 gap-x-1.5 rounded-md',
        icon: 'w-3 h-3'
      },
      xs: {
        base: 'text-display-14 gap-x-2 rounded-md',
        icon: 'w-4 h-4'
      },
      sm: {
        base: 'text-display-16 gap-x-2 rounded-lg',
        icon: 'w-4 h-4'
      },
      md: {
        base: 'text-display-18 gap-x-2 rounded-lg',
        icon: 'w-5 h-5'
      },
      lg: {
        base: 'text-display-20 gap-x-2 rounded-lg',
        icon: 'w-6 h-6'
      },
      store: {
        base: 'text-display-18 gap-x-2 rounded-lg',
        icon: 'w-7 h-7'
      }
    },
    weight: {
      regular: 'font-regular',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold'
    },
    fluid: {
      true: 'flex w-full'
    },
    fluidOnMobile: {
      true: 'flex w-full md:w-auto'
    },
    rounded: {
      true: 'rounded-full'
    },
    isImage: {
      false: 'transition-colors duration-300',
      true: ''
    },
    isIconOnly: {
      true: 'flex justify-center'
    },
    wrap: {
      false: 'whitespace-nowrap',
      true: 'whitespace-normal'
    }
  },
  defaultVariants: {
    appearance: 'text',
    size: 'md',
    color: 'white',
    weight: 'regular',
    fluid: false,
    fluidOnMobile: false,
    isImage: false,
    isIconOnly: false,
    wrap: false
  },
  compoundVariants: [
    // button
    {
      appearance: ['solid', 'outline', 'ghost'],
      class: {
        base: 'border border-transparent justify-center text-center'
      }
    },

    // link
    {
      appearance: ['text'],
      class: {
        base: ''
      }
    },

    // link / color
    {
      appearance: 'text',
      color: 'white',
      class: {
        base: colors.text.white
      }
    },
    {
      appearance: 'text',
      color: 'whiteAcqua',
      class: {
        base: colors.text.whiteAcqua
      }
    },
    {
      appearance: 'text',
      color: 'black',
      class: {
        base: colors.text.black
      }
    },
    {
      appearance: 'text',
      color: 'gray',
      class: {
        base: colors.text.gray
      }
    },
    {
      appearance: 'text',
      color: 'grayDark',
      class: {
        base: colors.text.grayDark
      }
    },
    {
      appearance: 'text',
      color: 'acqua',
      class: {
        base: colors.text.acqua
      }
    },
    {
      appearance: 'text',
      color: 'acquaDark',
      class: {
        base: colors.text.acquaDark
      }
    },
    {
      appearance: 'text',
      color: 'turquoise',
      class: {
        base: colors.text.turquoise
      }
    },
    {
      appearance: 'text',
      color: 'mint',
      class: {
        base: colors.text.mint
      }
    },

    // solid / color
    {
      appearance: 'solid',
      color: 'white',
      class: {
        base: colors.solid.white
      }
    },
    {
      appearance: 'solid',
      color: 'whiteAcqua',
      class: {
        base: colors.solid.whiteAcqua
      }
    },
    {
      appearance: 'solid',
      color: 'black',
      class: {
        base: colors.solid.black
      }
    },
    {
      appearance: 'solid',
      color: 'gray',
      class: {
        base: colors.solid.gray
      }
    },
    {
      appearance: 'solid',
      color: 'grayDark',
      class: {
        base: colors.solid.grayDark
      }
    },
    {
      appearance: 'solid',
      color: 'acqua',
      class: {
        base: colors.solid.acqua
      }
    },
    {
      appearance: 'solid',
      color: 'acquaDark',
      class: {
        base: colors.solid.acquaDark
      }
    },
    {
      appearance: 'solid',
      color: 'turquoise',
      class: {
        base: colors.solid.turquoise
      }
    },
    {
      appearance: 'solid',
      color: 'mint',
      class: {
        base: colors.solid.mint
      }
    },

    // outline / color
    {
      appearance: 'outline',
      color: 'white',
      class: {
        base: colors.outline.white
      }
    },
    {
      appearance: 'outline',
      color: 'whiteAcqua',
      class: {
        base: colors.outline.whiteAcqua
      }
    },
    {
      appearance: 'outline',
      color: 'black',
      class: {
        base: colors.outline.black
      }
    },
    {
      appearance: 'outline',
      color: 'gray',
      class: {
        base: colors.outline.gray
      }
    },
    {
      appearance: 'outline',
      color: 'grayDark',
      class: {
        base: colors.outline.grayDark
      }
    },
    {
      appearance: 'outline',
      color: 'acqua',
      class: {
        base: colors.outline.acqua
      }
    },
    {
      appearance: 'outline',
      color: 'acquaDark',
      class: {
        base: colors.outline.acquaDark
      }
    },
    {
      appearance: 'outline',
      color: 'turquoise',
      class: {
        base: colors.outline.turquoise
      }
    },
    {
      appearance: 'outline',
      color: 'mint',
      class: {
        base: colors.outline.mint
      }
    },

    // ghost / color
    {
      appearance: 'ghost',
      color: 'white',
      class: {
        base: colors.ghost.white
      }
    },
    {
      appearance: 'ghost',
      color: 'whiteAcqua',
      class: {
        base: colors.ghost.whiteAcqua
      }
    },
    {
      appearance: 'ghost',
      color: 'black',
      class: {
        base: colors.ghost.black
      }
    },
    {
      appearance: 'ghost',
      color: 'gray',
      class: {
        base: colors.ghost.gray
      }
    },
    {
      appearance: 'ghost',
      color: 'grayDark',
      class: {
        base: colors.ghost.grayDark
      }
    },
    {
      appearance: 'ghost',
      color: 'acqua',
      class: {
        base: colors.ghost.acqua
      }
    },
    {
      appearance: 'ghost',
      color: 'acquaDark',
      class: {
        base: colors.ghost.acquaDark
      }
    },
    {
      appearance: 'ghost',
      color: 'turquoise',
      class: {
        base: colors.ghost.turquoise
      }
    },
    {
      appearance: 'ghost',
      color: 'mint',
      class: {
        base: colors.ghost.mint
      }
    },

    // button / size
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'xxs',
      isImage: false,
      class: {
        base: 'px-2.5 h-8'
      }
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'xs',
      isImage: false,
      class: {
        base: 'px-3 h-10'
      }
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'sm',
      isImage: false,
      class: {
        base: 'px-4 h-11'
      }
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'md',
      isImage: false,
      class: {
        base: 'px-6 h-13'
      }
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'lg',
      isImage: false,
      class: {
        base: 'px-6 h-14'
      }
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'store',
      isImage: false,
      class: {
        base: 'px-3.5 h-13 leading-none'
      }
    },

    // Focus
    {
      appearance: ['solid', 'outline', 'ghost'],
      className: 'lg:focus-visible:outline-offset-2'
    },
    {
      appearance: 'text',
      className: 'lg:focus-visible:outline-offset-8'
    },
    {
      color: ['white', 'whiteAcqua', 'black', 'gray', 'grayDark', 'acqua'],
      className: {
        base: 'focus-visible:outline-acqua-500'
      }
    },
    {
      color: 'acquaDark',
      className: {
        base: 'focus-visible:outline-acqua-900'
      }
    },
    {
      color: 'turquoise',
      className: {
        base: 'focus-visible:outline-turquoise-500'
      }
    },
    {
      color: 'mint',
      className: {
        base: 'focus-visible:outline-mint-500'
      }
    },

    // isIconOnly
    {
      isIconOnly: true,
      isImage: false,
      size: 'xxs',
      class: {
        base: 'w-8 p-0'
      }
    },
    {
      isIconOnly: true,
      isImage: false,
      size: 'xs',
      class: {
        base: 'w-10 p-0'
      }
    },
    {
      isIconOnly: true,
      isImage: false,
      size: 'sm',
      class: {
        base: 'w-11 p-0'
      }
    },
    {
      isIconOnly: true,
      isImage: false,
      size: 'md',
      class: {
        base: 'w-13 p-0'
      }
    },
    {
      isIconOnly: true,
      isImage: false,
      size: 'lg',
      class: {
        base: 'w-14 p-0'
      }
    },
    {
      isIconOnly: true,
      isImage: false,
      size: 'store',
      class: {
        base: 'w-13 p-0'
      }
    }
  ]
})

export type ButtonVariantProps = Omit<
  VariantProps<typeof button>,
  'isIconOnly' | 'isImage' | 'isDisabled'
>
